import React, { useState } from "react";
import styled from "styled-components";

const Text = styled.text`
  font-size: 1rem;
  font-family: Montserrat-Bold;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;
const StyleButton = styled(Text).attrs({ role: "button" })`
  position: relative;
  display: flex;
  align-items: center;
  color: #c443ff;
  display:flex;
  justify-content:center;
`;

const Tooltip = styled.div`
  display: ${({ isTooltipDisplayed }) =>
    isTooltipDisplayed ? "block" : "none"};
  position: absolute;
  bottom: -26px;
  right: 0;
  left: 0;
  text-align: center;
  background-color: #c443ff;
  color: white;
  border-radius: 16px;
  opacity: 0.7;
  width:50%;
  margin:auto;
`;

const CopyIcon = styled.img``;

const CopyToClipboard = ({ toCopy, children, ...props }) => {
  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false);

  return (
    <StyleButton
      small
      bold
      onClick={() => {
        if (navigator.clipboard) {
          navigator.clipboard.writeText(toCopy);
          setIsTooltipDisplayed(true);
          setTimeout(() => {
            setIsTooltipDisplayed(false);
          }, 1000);
        }
      }}
      {...props}
    >
      {children}
      <CopyIcon src="/images/copy.svg"  width="20px" color="" ml="4px" />
      <Tooltip isTooltipDisplayed={isTooltipDisplayed}>Copied</Tooltip>
    </StyleButton>
  );
};

export default CopyToClipboard;
