import React, { useState } from "react";
import styled from "styled-components";
import ConnectBtn from "../ConnectBtn/ConnectBtn";
import { Link,NavLink } from "react-router-dom";
import { device } from "../../styles/styles";
import { slide as Menu } from "react-burger-menu";
import HogiModal from "../Modal/Modal";
import "../../styles/Menu.scss";
import connectors from "../Modal/components/config";
import ModalRow from "../Modal/components/ModalRow";
import { NavMenu } from "../../constants/constants";
import LogoutModal from "../Modal/components/LogOutModal";
import { useSelector } from "react-redux";
import { initializeWeb3 } from "../../services/web3.service";

const Nav = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const NavContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
`;
const NavLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const NavLogo = styled.img``;
const NavTabsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width 50%;
  align-items: center;
	font-family:"Montserrat-SemiBold";
  @media ${device.mobileL} {
    display: none;
  }

`;

const NavTab = styled.div`
  display: block;
  cursor: pointer;
  font-size: 14px;
  &:hover{
    color:#c443ff;
  }

`;
const StyledLink = styled(NavLink)`
&.active{
  color:#c443ff;
}

`;
const CloseIconContainer = styled.div`
  display: none;
  @media ${device.mobileL} {
    display: block;
  }
`;
const CloseIcon = styled.img`
  height: 30px;
  width: 30px;
`;
const ConnectModal = styled.div`
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

function Navbar() {
  const {
    selected: { address, isConnected },
  } = useSelector((state) => state.wallet);
  const [connectOpen, setConnectOpen] = useState(false);
  const handleConnectOpen = () => {
    setConnectOpen(!connectOpen);
  };
  const [menuOpenState, setMenuOpenState] = useState(false);
  const stateChangeHandler = (newState) => setMenuOpenState(newState.isOpen);
  const handleMenu = () => {
    setMenuOpenState(!menuOpenState);
  };
  const connectToWallet = async (value) => {
    if (!isConnected) {
      await initializeWeb3(value).catch((e) => {
        console.log(e);
      });
    } else {
      return;
    }
  };
  return (
    <Nav>
      <NavContainer>
        <NavLogoContainer>
          <Link to="/">
            <NavLogo src="/images/logo_hogi.svg" alt="" />
          </Link>
        </NavLogoContainer>
        <NavTabsContainer>
          {NavMenu &&
            NavMenu.map((menu, key) => (
              <NavTab key={key}>
                <StyledLink  exact to={menu.href}>{menu.title}</StyledLink>
              </NavTab>
            ))}
          <ConnectBtn
            handleOnClick={handleConnectOpen}
            connected={isConnected}
            account={address}
          />
        </NavTabsContainer>
        <div className="menuMobile">
          <Menu
            right
            disableAutoFocus
            customBurgerIcon={false}
            isOpen={menuOpenState}
            onStateChange={(state) => stateChangeHandler(state)}
          >
            <div className="bm-item" style={{display:"flex"}} onClick={handleMenu} >
              <ConnectBtn
                handleOnClick={handleConnectOpen}
                connected={isConnected}
                account={address}
              />
            </div>
            {NavMenu &&
              NavMenu.map((menu, key) => (
                <div key={key} onClick={handleMenu} className="bm-item">
                  <Link exact to={menu.href}>
                    <div className="burger-menu-link">
                      <div className="burger-menu-link__text">{menu.title}</div>
                    </div>
                  </Link>
                </div>
              ))}
          </Menu>
        </div>
        {connectors && !address ? (
          <HogiModal
            isOpen={connectOpen}
            toggle={handleConnectOpen}
            modaltitle={"Connect Your Wallet"}
          >
            <ConnectModal>
              {connectors.map((connector, index) => (
                <ModalRow
                  key={index}
                  title={connector.title}
                  icon={connector.icon}
                  walletConfig={connector}
                  login={connectToWallet}
                  onDismiss={handleConnectOpen}
                />
              ))}
            </ConnectModal>
          </HogiModal>
        ) : (
          <HogiModal
            isOpen={connectOpen}
            toggle={handleConnectOpen}
            modaltitle={"Your wallet"}
          >
            <ConnectModal>
              <LogoutModal toggle={handleConnectOpen} />
            </ConnectModal>
          </HogiModal>
        )}
        <CloseIconContainer onClick={handleMenu}>
          <CloseIcon src="/images/menu_icon.svg" alt="menu" />
        </CloseIconContainer>
      </NavContainer>
    </Nav>
  );
}

export default React.memo(Navbar);
