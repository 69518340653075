import { combineReducers } from "redux";
import { walletReducer } from "./WalletReducer";
import { toastsReducer } from "./ToastsReducer";

const rootReducer = combineReducers({
  wallet: walletReducer,
  toasts: toastsReducer,
});
export { rootReducer };
export type RootState = ReturnType<typeof rootReducer>;
