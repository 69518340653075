import React, { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";
import LoadingScreen from "./components/ScreenLoader/ScreenLoader";
import HomeLayout from "./Layout/Layout";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <HomeLayout>
      <Switch>
        {routes.map((route, i) => {
          const Component = route.component;
          // const Layout = route.layout || Fragment;
          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => <Component {...props} />}
            />
          );
        })}
      </Switch>
    </HomeLayout>
  </Suspense>
);
const routes = [
  {
    exact: true,
    path: "/",
    component: lazy(() => import("./Landing/Landing")),
    layout: HomeLayout,
  },
  {
    exact: true,
    path: "/my-nft",
    component: lazy(() => import("./My_nft/MyNft")),
    layout: HomeLayout,
  },
  {
    exact: true,
    path: "/auction",
    component: lazy(() => import("./Auction/Auction")),
    layout: HomeLayout,
  },
  {
    exact: true,
    path: "/nft/:id",
    component: lazy(() => import("./nftview/NftView")),
    layout: HomeLayout,
  },
  {
    exact: true,
    path: "/buy-nft",
    component: lazy(() => import("./buyNft/BuyNFT")),
    layout: HomeLayout,
  },
];

export default routes;
