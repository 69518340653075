import React from "react";
import "./screen.css";

function ScreenLoader() {
  return (
    <div>
      <div className="loading">Loading&#8230;</div>
    </div>
  );
}

export default ScreenLoader;
