export const howCard = [
  {
    image: "/images/1.svg",
    title: "Lorem ipsum dolor sit amet",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam",
  },
  {
    image: "/images/2.svg",
    title: "Lorem ipsum dolor sit amet",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam",
  },
  {
    image: "/images/3.svg",
    title: "Lorem ipsum dolor sit amet",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam",
  },
];

export const LandingFeaturedNFT = [
  {
    description: "Lorem ipsum dolor sit",
    tag: "hogi",
    image: "/images/nft1.jpg",
    price: "229",
  },
  {
    description: "Lorem ipsum dolor sit",
    tag: "hogi",
    image: "/images/nft2.jpg",
    price: "229",
  },
  {
    description: "Lorem ipsum dolor sit",
    tag: "hogi",
    image: "/images/nft3.jpg",
    price: "229",
  },
];
export const LandingFeaturedNFT1 = [
  {
    description: "Lorem ipsum dolor sit",
    tag: "hogi",
    image: "/images/nft1.jpg",
    price: "229",
  },
  {
    description: "Lorem ipsum dolor sit",
    tag: "hogi",
    image: "/images/nft2.jpg",
    price: "229",
  },
  {
    description: "Lorem ipsum dolor sit",
    tag: "hogi",
    image: "/images/nft3.jpg",
    price: "229",
  },
  {
    description: "Lorem ipsum dolor sit",
    tag: "hogi",
    image: "/images/nft1.jpg",
    price: "229",
  },
  {
    description: "Lorem ipsum dolor sit",
    tag: "hogi",
    image: "/images/nft2.jpg",
    price: "229",
  },
  {
    description: "Lorem ipsum dolor sit",
    tag: "hogi",
    image: "/images/nft3.jpg",
    price: "229",
  },
];

export const NavMenu = [
  { title: "Home", href: "/" },
  { title: "HOGI Auction", href: "/auction" },
  { title: "My NFTs", href: "/my-nft" },
  { title: "HOGI Loan", href: "/" },
  { title: "Buy NFTs", href: "/buy-nft" },
];

export const NetworkContextName = "NETWORK";

export const dropdown = [
  { text: "5 Grams", value: "5gram" },
  { text: "10 Grams", value: "10gram" },
  { text: "25 Grams", value: "25gram" },
  { text: "50 Grams", value: "50gram" },
  { text: "100 Grams", value: "100gram" },
];
