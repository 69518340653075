import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  //justify-content: space-between;
`;

const Searchbar = styled.input`
  width: 75%;
  padding: 15px;
  border-radius: 7px;
  outline: none;
  border: none;
  margin-right: 10px;
  background-color: ${(props) => (props.title ? `#f7f7f7` : `white`)};
`;
const ErrorText = styled.text`
  width: 100%;
  color: red;
`;

const Button = styled.div`
  background-color: #c443ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  :hover {
    background-color: #c443ff;
    box-shadow: 0px 15px 20px rgb(99 59 116 / 40%);
    transform: ${(props) => (props.connected ? "" : "translateY(-3px)")};
  }
`;
const IconText = styled.div`
  color: white;
  padding: 13px;
  font-size: 14px;
  font-family: Montserrat-Bold;
`;

function SearchBar({ title, placeholder }) {
  const initialState = {
    input: "",
    inputVal: "",
  };
  const [state, setState] = useState(initialState);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({
      ...state,
      input: value,
      inputVal: "",
    });
  };
  const validation = () => {
    let validate = true;

    const validateEmail = (input) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(input).toLowerCase());
    };
    if (title === "Search") {
      if (!state.input) {
        validate = false;
        setState((prev) => ({
          ...prev,
          inputVal: "Search should not be empty",
        }));
      }
    } else {
      if (!validateEmail(state.input)) {
        validate = false;
        setState((prev) => ({ ...prev, inputVal: "Email should be correct" }));
      }
    }

    return validate;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validation()) {
      return false;
    } else {
      alert(
        "Success! We have received your inquiry and will get back to you very soon."
      );
    }
  };
  return (
    <Container>
      <Wrapper>
        <Searchbar
          placeholder={placeholder ? placeholder : "Your Email"}
          onChange={handleChange}
          title
        ></Searchbar>
        <Button onClick={handleSubmit}>
          <IconText>{title}</IconText>
        </Button>
      </Wrapper>

      {state.inputVal && <ErrorText>{state.inputVal}</ErrorText>}
    </Container>
  );
}

export default SearchBar;
