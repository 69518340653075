import { PUSH, REMOVE, CLEAR } from "../actions/ToastsActionTypes";
const initialState = {
  data: [],
};
export function toastsReducer(state = initialState, action) {
  debugger
  let newState ;
  switch (action.type) {
    case PUSH:
      const { payload } = action;
      newState = { ...state };
      const toastIndex = newState.data.findIndex(
        (toast) => toast.id === action.payload.id
      );

      if (toastIndex >= 0) {
        newState.data.splice(toastIndex, 1);
      }
      newState.data.unshift(payload);
      return {
        ...newState,
      };
    case REMOVE:
      newState = { ...state };
      const toastIndexR = newState.data.findIndex(
        (toast) => toast.id === action.payload
      );

      if (toastIndexR >= 0) {
        newState.data.splice(toastIndexR, 1);
      }
      return {
        ...newState,
      };
    case CLEAR:
      return {
        ...state,
        data: [],
      };
    default:
      return state;
  }
}
