// import { Web3Provider } from "@ethersproject/providers";
// import { InjectedConnector } from "@web3-react/injected-connector";
// import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
// import { WalletLinkConnector } from "@web3-react/walletlink-connector";
// import { BscConnector } from "@binance-chain/bsc-connector";
// import { NetworkConnector } from "./NetworkConnector";

export const ConnectorNames = {
  Injected: "injected",
  WalletConnect: "walletconnect",
  BSC: "bsc",
};

const connectors = [
  {
    title: "Metamask",
    icon: "/images/Metamask.svg",
    connectorId: ConnectorNames.Injected,
  },
  // {
  //   title: "TrustWallet",
  //   icon: "/images/trustwallet.svg",
  //   connectorId: ConnectorNames.Injected,
  // },
  // {
  //   title: "MathWallet",
  //   icon: "/images/mathwallet.png",
  //   connectorId: ConnectorNames.Injected,
  // },
  // {
  //   title: "TokenPocket",
  //   icon: "/images/tokenpocket.png",
  //   connectorId: ConnectorNames.Injected,
  // },
  // {
  //   title: "WalletConnect",
  //   icon: "/images/walletconnect.png",
  //   connectorId: ConnectorNames.WalletConnect,
  // },
  // {
  //   title: "Binance Chain Wallet",
  //   icon: "/images/bscwallet.png",
  //   connectorId: ConnectorNames.BSC,
  // },
  // {
  //   title: "SafePal Wallet",
  //   icon: "/images/safepalwallet.png",
  //   connectorId: ConnectorNames.Injected,
  // },
];

// const NETWORK_URL = process.env.REACT_APP_NETWORK_URL;

// export const NETWORK_CHAIN_ID = parseInt(
//   process.env.REACT_APP_CHAIN_ID ?? "56"
// );

// if (typeof NETWORK_URL === "undefined") {
//   throw new Error(
//     `REACT_APP_NETWORK_URL must be a defined environment variable`
//   );
// }

// export const network = new NetworkConnector({
//   urls: { [NETWORK_CHAIN_ID]: NETWORK_URL },
// });

// let networkLibrary;
// export function getNetworkLibrary() {
//   return (networkLibrary =
//     networkLibrary ?? new Web3Provider(network.provider));
// }

// export const injected = new InjectedConnector({
//   supportedChainIds: [4, 56, 97, 256],
// });

// export const bscConnector = new BscConnector({ supportedChainIds: [56] });

// // mainnet only
// export const walletconnect = new WalletConnectConnector({
//   rpc: { [NETWORK_CHAIN_ID]: NETWORK_URL },
//   bridge: "https://bridge.walletconnect.org",
//   qrcode: true,
//   pollingInterval: 15000,
// });

// // mainnet only
// export const walletlink = new WalletLinkConnector({
//   url: NETWORK_URL,
//   appName: "Uniswap",
//   appLogoUrl:
//     "https://mpng.pngfly.com/20181202/bex/kisspng-emoji-domain-unicorn-pin-badges-sticker-unicorn-tumblr-emoji-unicorn-iphoneemoji-5c046729264a77.5671679315437924251569.jpg",
// });

// export const connectorsByName = {
//   [ConnectorNames.Injected]: injected,
//   [ConnectorNames.WalletConnect]: walletconnect,
//   [ConnectorNames.BSC]: bscConnector,
// };

// export const connectorLocalStorageKey = "connectorId";
export default connectors;
