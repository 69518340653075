import React from "react";
import styled from "styled-components";

const Button = styled.div`
  width: 200px;
  background-color: #c443ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  letter-spacing: 2.5px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  box-shadow: ${(props) =>
    props.connected ? " 0px 15px 20px rgba(196, 67, 255, 0.4)" : ""};
  :hover {
    background-color: #c443ff;
    box-shadow: 0px 15px 20px rgba(196, 67, 255, 0.4);
    transform: ${(props) => (props.connected ? "" : "translateY(-3px)")};
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const IconText = styled.p`
  color: white;
  font-size: 14px;
  padding-left: 10px;
  font-family: Montserrat-Regular;
`;
const Image = styled.img``;

const AccountNoContainer = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
`;
function ConnectBtn({ handleOnClick , account }) {
  return (
    <Button connected={account} onClick={handleOnClick}>
      {account ? (
        <AccountNoContainer>
          {account?.substring(1, 6) +
            "..." +
            account?.substring(account.length - 4)}
        </AccountNoContainer>
      ) : (
        <>
          <IconContainer>
            <Image src="/images/wallet.svg" height="20px" alt="" />
          </IconContainer>
          <IconText>Connect Wallet</IconText>
        </> 
      )}
    </Button>
  );
}

export default React.memo(ConnectBtn);
