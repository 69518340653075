import React from "react";
import styled from "styled-components";
import { device } from "../../../styles/styles";
import { connectorLocalStorageKey } from "../../Modal/components/config";
import CopyToClipboard from "../../CopyToClipboard/CopyToClipboard";
import { useSelector } from "react-redux";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;
  align-items: center;
`;
const AccountText = styled.div`
  font-size: 1.05rem;
  font-family: Montserrat-SemiBold;
  color: #c443ff;
  margin-bottom: 10px;
  word-break: break-word;
  padding:0px 15px;
`;

const ConnectBtnModal = styled.div`
  width: 40%;
  height: 44px;
  background: Transparent;
  border: 2px solid #c443ff;
  color: #c443ff;
  font-family: Montserrat-Semibold;
  font-size: 14px;
  letter-spacing: 1.3px;
  border-radius: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  :hover {
    background-color: #c443ff;
    box-shadow: 0px 15px 20px rgba(196, 67, 255, 0.4);
    color: #fff;
    transform: translateY(-3px);
  }
  @media ${device.mobileL} {
    height: 34px;
    font-size: 12px;
  }
`;
const CopyContainer = styled.div`
  width: 100%;
`;

function LogOutModal({ toggle }) {
  const {
    selected: { address },
  } = useSelector((state) => state.wallet);
  return (
    <Container>
      <CopyContainer>
        <AccountText>{address}</AccountText>
        <CopyToClipboard toCopy={address}>Copy Address</CopyToClipboard>
      </CopyContainer>
      {/* <ConnectBtnModal
        onClick={() => {
          logout();
          window.localStorage.removeItem(connectorLocalStorageKey);
          toggle()
        }}
      >
        Logout
      </ConnectBtnModal> */}
    </Container>
  );
}

export default LogOutModal;
