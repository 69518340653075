import React from "react";
import styled from "styled-components";
import { web3Sources } from "../../../constants";
import { device } from "../../../styles/styles";

const ConnectOption = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
`;

const ModalProvider = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
const ProviderLogoContainer = styled.div``;
const ProviderLogo = styled.img`
  max-width: 100%;
  height: 30px;
  width: 30px;
  @media ${device.mobileL} {
    height: 20px;
    width: 20px;
  }
`;

const ProviderTitle = styled.div`
  color: #c443ff;
  font-family: Montserrat-Semibold;
  @media ${device.mobileL} {
    font-size: 12px;
  }
`;

const ConnectBtnModal = styled.div`
  width: 40%;
  height: 44px;
  background: Transparent;
  border: 2px solid #c443ff;
  color: #c443ff;
  font-family: Montserrat-Semibold;
  font-size: 14px;
  letter-spacing: 1.3px;
  border-radius: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  :hover {
    background-color: #c443ff;
    box-shadow: 0px 15px 20px rgba(196, 67, 255, 0.4);
    color: #fff;
    transform: translateY(-3px);
  }
  @media ${device.mobileL} {
    height: 34px;
    font-size: 12px;
  }
`;
function ModalRow({ icon, title, login, walletConfig, onDismiss }) {
  return (
    <ConnectOption>
      <ModalProvider>
        <ProviderLogoContainer>
          <ProviderLogo src={icon} alt="provider" />
        </ProviderLogoContainer>
        <ProviderTitle>{title}</ProviderTitle>
      </ModalProvider>
      <ConnectBtnModal
        onClick={() => {
          login(web3Sources.Metamask);
          // window.localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId);
          onDismiss();
        }}
      >
        Connect Now
      </ConnectBtnModal>
    </ConnectOption>
  );
}

export default React.memo(ModalRow);
