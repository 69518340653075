import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import styles from "./Modal.module.scss";

const ModalContent = styled.div`
  width: 100%;
`;
const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
`;
const CancelContainer = styled.div`
  margin-right: 20px;
  cursor: pointer;
`;
const ModalTitle = styled.div`
  display: flex;
  width: 100%;
  font-size: 20px;
  font-family: Montserrat-Bold;
  color: #c443ff;
  margin-left: 25px;
  justify-content: center;
  margin-top: 35px;
`;
const CancelIcons = styled.img`
  width: 12px;
`;
const ContentContainer = styled.div`
  padding: 20px;
`;

const CustomModal = ({ isOpen, toggle, children, modaltitle }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggle}
      contentLabel="HOGI Modal"
      className={styles.modal}
      overlayClassName={styles.modalOverlay}
    >
      <ModalContent>
        <Header>
          <ModalTitle>{modaltitle}</ModalTitle>
          <CancelContainer>
            <CancelIcons
              onClick={toggle}
              src="/images/cancel.png"
              alt="cancel"
            />
          </CancelContainer>
        </Header>
        <ContentContainer>{children}</ContentContainer>
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
