import React from "react";
import styled from "styled-components";
import SearchBar from "../../components/Searchbar/SearchBar";
import { device } from "../../styles/styles";
import { Link } from "react-router-dom";
const Container = styled.div`
  height: 100%;
  background: #3b157a;
  display: flex;
  justify-content: center;
  padding: 50px 20px;
`;
const ContainerUpper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 85%;
  @media ${device.mobileL} {
    flex-direction: column-reverse;
    row-gap: 20px;
  }
`;
const ContainerSubUpper = styled.div`
  display: flex;
  width: 30%;
  justify-content: space-between;
  align-items: center;
  @media ${device.mobileL} {
    width: 100%;
    flex-direction: column;
    row-gap: 20px;
  }
`;
const LogoContainer = styled.div``;
const Logo = styled.div`
  font-family: Montserrat-SemiBold;
  font-size: 33px;
  text-align: left;
  color: #fff;
  text-transform: uppercase;
`;
const SubNavLinksContainer = styled.div`
  text-align: left;
  // display: flex;
  @media ${device.mobileL} {
    text-align: center;
  }
`;
const SubNavLinks = styled.div`
  font-family: Montserrat-Medium;
  font-size: 14px;
  line-height: 40px;
  color: #fff;
`;
const NewsLetterContainer = styled.div`
  display: flex;
  width: 40%;
  align-items: center;
  @media ${device.mobileL} {
    width: 100%;
  }
`;
const NewsletterTitle = styled.div`
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  text-align: left;
  color: #fff;
  @media ${device.mobileL} {
    text-align: center;
  }
`;
const NewsLetterSubContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const NewsLetterMiddleSection = styled.div`
  font-family: Montserrat-Bold;
  font-size: 26px;
  line-height: 50px;
  text-align: left;
  color: #fff;
  @media ${device.mobileL} {
    text-align: center;
  }
`;
const NewsLetterSubtitle = styled.div`
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  margin-bottom: 10px;
  text-align: left;
  color: #fff;
  margin-bottom: 20px;
  @media ${device.mobileL} {
    text-align: center;
  }
`;
const ContainerLower = styled.div`
  height: 47px;
  background: #3b157a;
  border-top: 1px solid #fff;
  @media ${device.mobileL} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const ContainerLowerTextContainer = styled.div`
  font-family: Montserrat-Regular;
  padding: 10px;
  padding-left: 7.5%;
  font-size: 14px;
  line-height: 23px;
  text-align: left;
  color: #fff;
  @media ${device.mobileL} {
    text-align: center;
    padding: 0px;
  }
`;

function Footer() {
  return (
    <React.Fragment
      data-aos="fade-up"
      data-aos-offset="200"
      data-aos-easing="ease-in-sine"
      data-aos-duration="600"
    >
      <Container>
        <ContainerUpper>
          <ContainerSubUpper>
            <LogoContainer>
              <Logo>
                <Link to="/">HOGI NFT</Link>
              </Logo>
            </LogoContainer>
            <SubNavLinksContainer>
              <SubNavLinks>
                <Link to="/auction">HOGI Auction</Link>
              </SubNavLinks>
              <SubNavLinks>
                <Link to="/my-nft">My NFTs</Link>
              </SubNavLinks>
            
              <SubNavLinks>
                <Link>HOGI Loan</Link>
              </SubNavLinks>
            </SubNavLinksContainer>
          </ContainerSubUpper>
          <NewsLetterContainer>
            <NewsLetterSubContainer>  
              <NewsletterTitle>NewsLetter</NewsletterTitle>
              <NewsLetterMiddleSection>Subscribe HOGI</NewsLetterMiddleSection>
              <NewsLetterSubtitle>For our future updates</NewsLetterSubtitle>
              <SearchBar title={"Subscribe"} />
            </NewsLetterSubContainer>
          </NewsLetterContainer>
        </ContainerUpper>
      </Container>
      <ContainerLower>
        <ContainerLowerTextContainer>
          Copyright © HOGI project. All rights reserved.
        </ContainerLowerTextContainer>
      </ContainerLower>
    </React.Fragment>
  );
}

export default Footer;
