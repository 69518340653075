
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import { web3Sources } from "../constants";
import { store } from "../App";
import {
  loadingBalancePending,
  resetWalletsInfoAction,
  saveWalletsInfoAction,
  SaveWeb3InfoAction,
  SetSelectedWalletAction,
} from "../store/actions/WalletActions";
import { Wallet } from "../store/types/WalletState";


// let web3Wrapper: Web3Wrapper | null = null;
const web3: Web3 = new Web3();

// @ts-ignore
export const initializeWeb3 = async (source: web3Sources): Promise<any> => {
  store.dispatch(loadingBalancePending());
  try {
    switch (source) {
      case web3Sources.Metamask:
        await initMetamask();
        break;
      case web3Sources.MEW:
        await initMEW();
        break;
    }
    if (web3.currentProvider) {
      store.dispatch(SaveWeb3InfoAction(source, web3));
      await updateDAssets();
    } else {
      throw new Error("Error while accessing web3 provider.");
    }
  } catch (e) {
  }
};

export const updateDAssets = async () => {
  
  const accounts = await web3.eth.getAccounts();
  if (accounts.length <= 0) {
    throw new Error("Error, Provider is not available");
  }

  let wallets: Wallet[] = [];
  let isDefaultSelected = false;
  for (let i = 0; i < accounts.length; i++) {
    let walletObj: Wallet = {
      address: accounts[i],
      isConnected : true,
    };
    wallets.push(walletObj);
    if (!isDefaultSelected) {
      isDefaultSelected = true;
      store.dispatch(SetSelectedWalletAction(walletObj));
    }
  }
  if (wallets.length <= 0) {
    throw new Error("Error while accessing accounts.");
  }
  store.dispatch(saveWalletsInfoAction(wallets));
};

// @ts-ignore
export const initMetamask = async (): Promise<Web3> => {
  //@ts-ignore
  const provider = await detectEthereumProvider();

  if (provider) {
    try {
      // @ts-ignore
      web3.setProvider(provider);
      // Request account access if needed
      // @ts-ignore
      // await provider.request('eth_requestAccounts')
      await provider.enable();
      // Subscriptions register
      //@ts-ignore
      provider.on("accountsChanged", async (accounts: []) => {
        // Reload to avoid MetaMask bug: "MetaMask - RPC Error: Internal JSON-RPC"
        window.location.reload();
      });
      //@ts-ignore
      provider.on("chainChanged", async (network: number) => {
        window.location.reload();
      });
      // localStorage.saveWalletConnected(web3Sources.Metamask);
      return web3;
    } catch (error) {
      // The user denied account access
    }
  } else {
    // @ts-ignore
    if (window.web3) {
      // @ts-ignore
      web3.setProvider(window.web3.currentProvider);
      return web3;
    } else {
      //localStorage.resetWalletConnected();
      store.dispatch(resetWalletsInfoAction());
      //  The user does not have metamask installed
    }
  }
};
export const initMEW = () => {
  // ethereum.send("eth_requestAccounts").then((accounts) => {
  //   console.log(`User's address is ${accounts[0]}`)
  
  // })
}
